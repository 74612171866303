import { useEffect, useState } from 'react'

import { useProposal, useLead } from '@itau-loans-www/shopping/src/hooks'
import { http } from '@itau-loans-www/shopping/src/services'
import analytics from '@mobi/libraries/analytics'
import { goTo } from 'utils'

const useResult = ({ isToGetNumber = false }) => {
  const proposalContext = useProposal()
  const { changeLeadData } = useLead()
  const productCode = proposalContext?.product?.proposal?.productCode
  const [phone, setPhone] = useState('')
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const getData = async () => {
      await http.proposal
        .getLeadPhone()
        .then((response) => {
          setPhone(response.lead_phone_number)
          setIsLoading(false)
        })
        .catch(() => setIsLoading(false))
    }
    if (isToGetNumber) getData()
  }, [isToGetNumber])

  useEffect(() => {
    const handlePopState = async (e) => {
      e.preventDefault()
      history.pushState(null, null, window.location.pathname)

      const nextStep = await http.proposal.crossSell()
      goTo(nextStep)
    }

    window.addEventListener('popstate', handlePopState)

    return () => window.removeEventListener('popstate', handlePopState)
  }, [])

  useEffect(() => {
    changeLeadData({ previousStep: '' })
    analytics.track('pageLoad', {
      currentPage: 'telas-finais-de-redirect-do-produto',
      customLayer: {
        description: productCode
      }
    })
  }, [productCode])

  return {
    phone,
    isLoading
  }
}

export { useResult }
