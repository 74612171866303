import { IdsAlert } from '@ids/react'
import {
  If,
  Footer,
  Feedback,
  CookieBar
} from '@itau-loans-www/shopping/src/components'
import { useLocation } from '@reach/router'

import SEO from './components/SEO'
import useMain from './useMain'

export default ({ children, Header, hasFooter, hasCookieBar = true }) => {
  const location = useLocation()
  const { title, alertContent, isAlertActive, setIsAlertActive } = useMain({
    location
  })

  return (
    <>
      <SEO title={title} />
      <Feedback />
      <IdsAlert
        setShow={setIsAlertActive}
        variant={alertContent?.type}
        show={isAlertActive}
        icon={alertContent?.icon}
        header={alertContent?.title}
      >
        {alertContent?.description}
      </IdsAlert>
      <If condition={hasCookieBar} renderIf={<CookieBar />} />
      {Header && <Header />}
      {children}
      <If condition={hasFooter} renderIf={<Footer />} />
    </>
  )
}
